@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

:root {
  --bg-color-ptimary: #000000;
  --color-active: #b20e17;
  --color-active-highlight: #ca343c;
  /* border: 2px solid #828282; */
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
}

html {
  height: 100%;
}

body {
  background-color: var(--bg-color-ptimary);
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  width: 100%;
  height: 46px;

  border: none;
  outline: none;

  padding: 0px 20px;

  border-radius: 5px;

  color: white;
  background-color: var(--color-active);

  font-size: 16px;
  letter-spacing: 1px;

  cursor: pointer;

  transition: all 0.2s ease-in-out;

  border: 2px solid var(--color-active);
}
button:hover {
  background-color: var(--color-active-highlight);
}